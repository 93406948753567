<template>
  <div v-show="show" class="container">
    <div class="d-flex justify-content-between mb-3 align-items-center">
      <h2 class="col-8 text-start">Lista de Videos</h2>
      <button
        type="button"
        class="
          btn btn-outline-success
          fs-2
          rounded-circle
          d-flex
          justify-content-center
          align-items-center
        "
        @click="onAddVideo()"
        style="height: 50px; width: 50px"
      >
        +
      </button>
    </div>
    <div>
      <div class="video-list row">
        <ul class="list-unstyled col">
          <li :key="video.id" v-for="video in videoList">
            <div class="mt-4">
              <div class="card mb-3">
                <div class="row g-0">
                  <div class="col-md-2">
                    <img
                      :src="video.thumbnail"
                      class="img-fluid rounded-start w-100 h-100"
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <h5 class="card-title">{{ video.title }}</h5>
                      <p class="card-text text-start">
                        {{ video.bio.split(". ")[0] }}.
                        {{
                          video.bio.split(". ")[1]
                            ? video.bio.split(". ")[1].substring(0, 80) + "..."
                            : "."
                        }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="
                      col-md-2
                      d-flex
                      justify-content-center
                      align-items-center
                    "
                  >
                    <div class="container">
                      <div class="row">
                        <div class="col">
                          <button
                            class="btn btn-danger"
                            @click="onDeleteVideo(video)"
                          >
                            Borrar
                          </button>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col">{{ video.votes }} Votos</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoList",
  props: {
    contests: Object,
    selectedContest: String,
    show: Boolean,
    videoList: Array,
    onAddVideo: Function,
  },
  methods: {
    onDeleteVideo(video) {
      alert(`Esta seguro que desea borrar el video ${video.title}?`);
    },
  },
};
</script>

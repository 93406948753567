import { auth, provider, contestsCollection } from "../utils/firebaseConfig";

const login = () => {
  return auth.signInWithPopup(provider);
};

const logout = () => auth.signOut();

const getContest = (contest) => contestsCollection.doc(contest).get();
const updateContest = (contest, payload) =>
  contestsCollection.doc(contest).update(payload);

const getVideos = (contest) =>
  contestsCollection.doc(contest).collection("videos").get();

const addBandsVideo = (payload) =>
  contestsCollection.doc("bands").collection("videos").add(payload);

const addDanceVideo = (payload) =>
  contestsCollection.doc("dance").collection("videos").add(payload);

const updateVideo = () => {
  return new Promise();
};

const deleteVideo = () => {
  return new Promise();
};

export {
  login,
  logout,
  getVideos,
  getContest,
  updateContest,
  addBandsVideo,
  addDanceVideo,
  updateVideo,
  deleteVideo,
};

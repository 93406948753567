<template>
  <div class="d-flex mt-3 row w-100">
    <div class="contest-menu mb-5 col-sm-2">
      <h5>Seleccionar Concurso</h5>
      <div class="adminBar d-flex flex-column justify-content-between">
        <div class="list-group">
          <a
            href="#"
            @click="onSelectContest('vamoslasbandas')"
            class="list-group-item list-group-item-action"
            :class="selectedContest === 'vamoslasbandas' ? 'active' : ''"
            :aria-current="
              selectedContest === 'vamoslasbandas' ? 'true' : 'false'
            "
          >
            Vamos las Bandas
          </a>
          <a
            href="#"
            @click="onSelectContest('concursodanza')"
            class="list-group-item list-group-item-action"
            :class="selectedContest === 'concursodanza' ? 'active' : ''"
            :aria-current="
              selectedContest === 'concursodanza' ? 'true' : 'false'
            "
          >
            Concurso de Danzas
          </a>
        </div>
        <div class="d-flex flex-wrap mt-5">
          <button
            class="btn btn-success mt-1 mb-4 col-12"
            @click="findWinner()"
          >
            Buscar Ganador
          </button>
          <button
            class="btn btn-success mt-1 col-12"
            @click="activateContest()"
          >
            Activar Concurso
          </button>
          <button class="btn btn-danger mt-1 col-12" @click="pauseContest()">
            Pausar Concurso
          </button>
        </div>
      </div>
    </div>
    <div class="video-list col-sm-10">
      <VideoList
        :show="showVideoList"
        :selectedContest="selectedContest"
        :videoList="videoList"
        :contests="contests"
        :onAddVideo="onAddVideo"
      ></VideoList>
      <AddVideo
        :show="showContestForm"
        :selectedContest="selectedContest"
        :onCancelAddVideo="onCancelAddVideo"
        :pageTitle="`Agregar Video a ${contests[selectedContest].name}`"
      />
    </div>
    <div class="add-video">
      <Modal :closeModal="closeModal" :show="show" v-bind:close="closeModal">
        <h3>Estas a punto de iniciar sesion con Google</h3>
      </Modal>
    </div>
  </div>
</template>

<script>
import AddVideo from "@/components/AddVideo.vue";
import Modal from "@/components/Modal.vue";
import VideoList from "@/components/VideoList.vue";
import { getVideos, getContest, updateContest } from "../../api";

export default {
  name: "Contests",
  components: {
    AddVideo,
    Modal,
    VideoList,
  },
  data() {
    return {
      contestWinners: [],
      show: false,
      showContestForm: false,
      showVideoList: true,
      selectedContest: "vamoslasbandas",
      videoList: [],
      contests: {
        vamoslasbandas: {
          id: "vamoslasbandas",
          name: "Vamos Las Bandas",
          dbName: "bands",
        },
        concursodanza: {
          id: "concursodanza",
          name: "Concurso de Danzas",
          dbName: "dance",
        },
      },
      contestTitle: "",
    };
  },
  methods: {
    activateContest() {
      console.log(this.contests[this.selectedContest].dbName);
      getContest(this.contests[this.selectedContest].dbName).then(
        (querySnapshot) => {
          const { isActive } = querySnapshot.data();
          if (isActive) {
            alert("El concurso ya se encuentra activo");
          } else {
            if (
              confirm(
                `Esta seguro que desea activar el concurso ${
                  this.contests[this.selectedContest].name
                }? Será visible en la pagina principal de concursos de FCP.`
              )
            ) {
              updateContest(this.contests[this.selectedContest].dbName, {
                isActive: true,
              }).then(() => alert("Concurso activado con exito"));
            }
          }
        }
      );
    },
    findWinner() {
      this.getContestVideos(this.selectedContest);
      const maxVotesNumber = Math.max.apply(
        Math,
        this.videoList.map((video) => video.votes)
      );
      const contestWinners = this.videoList.filter(
        (video) => video.votes == maxVotesNumber
      );
      this.contestWinners = contestWinners;
      alert(
        `Los ganadores del concurso ${
          this.contests[this.selectedContest].name
        } son ${this.contestWinners.map((winner) => "\n" + winner.title)}`
      );
    },
    pauseContest() {
      getContest(this.contests[this.selectedContest].dbName).then(
        (querySnapshot) => {
          const { isActive } = querySnapshot.data();
          if (!isActive) {
            alert("El concurso ya se encuentra desactivado");
          } else {
            if (
              confirm(
                `Esta seguro que desea pausar el concurso ${
                  this.contests[this.selectedContest].name
                }? Ya no será visible en la pagina principal de concursos de FCP.`
              )
            ) {
              updateContest(this.contests[this.selectedContest].dbName, {
                isActive: false,
              }).then(() => alert("Concurso desactivado con exito"));
            }
          }
        }
      );
    },
    onAddVideo() {
      this.showVideoList = false;
      this.showContestForm = true;
    },
    onCancelAddVideo() {
      this.showContestForm = false;
      this.showVideoList = true;
    },
    onSelectContest(id) {
      this.showContestForm = false;
      this.showVideoList = true;
      this.selectedContest = id;
      this.contestTitle = this.contests[id].name;
      this.getContestVideos(id);
    },
    closeModal() {
      this.show = false;
    },
    _arrangeVideoList(querySnapshot) {
      this.videoList = [];
      if (querySnapshot) {
        querySnapshot.forEach((doc) => {
          const youtubeVideoId = doc
            .data()
            .url.split("https://www.youtube.com/watch?v=")[1]
            .split("&")[0];
          const thumbnail = youtubeVideoId
            ? `https://img.youtube.com/vi/${youtubeVideoId}/0.jpg`
            : "";
          this.videoList.push({
            thumbnail,
            id: doc.id,
            ...doc.data(),
          });
        });
      }
    },
    getContestVideos(selectedContest) {
      switch (selectedContest) {
        case "vamoslasbandas":
          getVideos("bands").then((querySnapshot) => {
            this._arrangeVideoList(querySnapshot);
          });
          break;
        case "concursodanza":
          getVideos("dance").then((querySnapshot) => {
            this._arrangeVideoList(querySnapshot);
          });
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    this.getContestVideos(this.selectedContest);
  },
};
</script>

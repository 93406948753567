<template>
  <div v-show="show" class="container">
    <div class="d-flex justify-content-between mb-3 align-items-center">
      <h4 class="col-8 text-start">{{ pageTitle }}</h4>
      <button
        type="button"
        class="
          btn btn-outline-success
          rounded-pill
          d-flex
          justify-content-center
          align-items-center
          fs-6
        "
        @click="cancelAddVideo()"
      >
        Cancelar
      </button>
    </div>
    <form @submit.prevent="submit">
      <div class="mb-3">
        <label htmlFor="url" class="form-label">Video URL</label>
        <input
          id="url"
          class="form-control"
          placeholder="https://youtube.com/videoUrl"
          required
          type="url"
          v-model.trim="url"
          :class="[!validateForm() ? errorClass : '']"
        />
      </div>
      <div class="mb-3">
        <label htmlFor="title" class="form-label">Titulo del video</label>
        <input
          id="title"
          class="form-control"
          placeholder="Titulo del video"
          required
          type="text"
          v-model.trim="title"
        />
      </div>
      <div class="mb-3">
        <label htmlFor="artist" class="form-label">Artista</label>
        <input
          id="artist"
          class="form-control"
          placeholder="Artista"
          required
          type="text"
          v-model.trim="artist"
        />
      </div>
      <div class="mb-3">
        <label htmlFor="bio" class="form-label">Bio</label>
        <textarea
          class="form-control"
          placeholder="Bio del Artista"
          id="description"
          v-model.trim="bio"
          rows="3"
        ></textarea>
      </div>
      <div class="mb-3">
        <label htmlFor="description" class="form-label"
          >Descripción del Video</label
        >
        <textarea
          class="form-control"
          placeholder="Descripción corta del video"
          id="description"
          v-model.trim="description"
          rows="3"
        ></textarea>
      </div>
      <div class="mb-3">
        <label htmlFor="contact" class="form-label">Contacto</label>
        <input
          class="form-control"
          placeholder="3513550986, banda@gmail.com"
          id="contact"
          v-model.trim="contact"
          rows="3"
        />
      </div>
      <button
        class="btn btn-primary"
        role="submit"
        type="button"
        :disabled="!validateForm()"
        v-on:click="onSubmitForm"
      >
        Enviar
      </button>
    </form>
  </div>
</template>

<script>
import { addBandsVideo, addDanceVideo } from "../api";
export default {
  name: "AddVideo",
  props: {
    pageTitle: String,
    show: Boolean,
    selectedContest: String,
    onCancelAddVideo: Function,
  },
  data() {
    return {
      artist: "",
      bio: "",
      contact: "",
      description: "",
      errorClass: "is-invalid",
      pattern: new RegExp(
        /^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/
      ),
      title: "",
      url: "",
    };
  },
  methods: {
    /* @TODO: Refactor this method */
    resetForm() {
      this.artist = "";
      this.bio = "";
      this.contact = "";
      this.description = "";
      this.title = "";
      this.url = "";
    },
    validateForm() {
      return this.url.match(this.pattern);
    },
    cancelAddVideo() {
      this.resetForm();
      this.onCancelAddVideo();
    },
    onSubmitForm() {
      switch (this.selectedContest) {
        case "vamoslasbandas":
          addBandsVideo({
            artist: this.artist,
            bio: this.bio,
            contact: this.contact,
            description: this.description,
            title: this.title,
            url: this.url,
            votes: 0,
          }).then(() => {
            alert("Video enviado correctamente al concurso de bandas!");
            this.resetForm();
          });
          break;
        case "concursodanza":
          addDanceVideo({
            artist: this.artist,
            bio: this.bio,
            contact: this.contact,
            description: this.description,
            title: this.title,
            url: this.url,
            votes: 0,
          }).then(() => {
            alert("Video enviado correctamente al concurso de danza!");
            this.resetForm();
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.error {
  border-color: red;
}
textarea {
  resize: none;
}
</style>
